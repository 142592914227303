import { reactive, toRefs, defineAsyncComponent, computed } from "vue";
import API from "@/api/authority/index";
import { useMessage } from '@/hooks/web/useMessage';
import { useStore } from "vuex";
import authorityAPI from "@/api/authority/index";
export default {
  name: "RenewalOper",
  props: {
    isExport: {
      type: Boolean,
      default: false
    },
    sign: {
      type: String,
      default: ''
    }
  },
  components: {
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue")),
    PromptBox: defineAsyncComponent(() => import("@/components/PromptBox/index.vue"))
  },
  emits: ['handleSuccess', 'handleError'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const {
      dispatch,
      state
    } = useStore();
    const refData = reactive({
      title: "续费",
      visible: false,
      type: null,
      promptBoxRefs: null,
      total: 0,
      imei: "",
      expireDate: "",
      cardIds: [],
      errorList: [],
      typeList: []
    });
    const totalCoin = computed(() => {
      return refData.type === -1 ? 25 * refData.cardIds.length : refData.cardIds.length * refData.typeList.find(item => item.renewMonth === refData.type).renewCoin;
    });
    //确定按钮
    const confirm = async () => {
      if (totalCoin.value > state.userInfo.coin) return message.warning('当前币数不足，无法续费');
      if (refData.type === null) return message.warning('请选择授权时长');
      // 勾选续费
      if (props.isExport) {
        const {
          msg,
          code,
          data
        } = await authorityAPI.cardImportAndRenew({
          sign: props.sign,
          addMonth: refData.type,
          isRenew: 1,
          isUpdate: 0
        });
        if (code == 0) {
          refData.visible = false;
          message.success('续费成功');
          emit('handleSuccess');
          dispatch('getUserInfo');
        } else {
          emit('handleError', data);
          refData.visible = false;
        }
      } else {
        const {
          msg,
          code,
          data
        } = await API.batchRenew({
          cardIds: refData.cardIds,
          addMonth: refData.type
        });
        if (code === 0) {
          message.success('续费成功');
          emit('handleSuccess');
          refData.visible = false;
        } else {
          if (code === 51002) {
            refData.errorList = data;
            refData.promptBoxRefs.visible = true;
          } else {
            message.warning(msg);
          }
        }
      }
    };
    //获取续费选项
    const getRenewCion = async () => {
      const {
        data,
        code
      } = await API.getRenewCion();
      if (code == 0 && data) {
        refData.typeList = data.map(item => {
          return {
            ...item,
            renewName: item.renewMonth === -1 ? '终身' : item.renewMonth < 12 ? `${item.renewMonth}个月` : `${item.renewMonth / 12}年`
          };
        });
      }
    };
    const handleClear = async () => {
      if (props.isExport) {
        const {
          code,
          data
        } = await authorityAPI.cardImportAndRenew({
          sign: props.sign,
          addMonth: 0,
          isRenew: 0,
          isUpdate: 1
        });
        if (code === 0) {
          message.success("上传成功");
        } else {
          emit('handleError', data);
        }
      }
    };
    getRenewCion();
    return {
      ...toRefs(refData),
      confirm,
      handleClear,
      totalCoin
    };
  }
};